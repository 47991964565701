<template>
  <div class="farm">
    <div class="template-page-top">
      <div class="title">农场信息</div>
      <div class="preserve">
        <el-button
          class="button-preserve"
          type="primary"
          @click="postEditFarmApi"
          >保 存</el-button
        >
      </div>
    </div>
    <div class="farm-form-items">
      <span class="label">农场名称</span>
      <el-input
        v-model.trim="name"
        placeholder="请输入内容"
        maxlength="20"
      ></el-input>
    </div>
    <div class="farm-form-items">
      <span class="label">农场封面</span>
      <el-upload
        class="upload-img"
        ref="coverUpload"
        :class="{ disabled: isCoverUploadDisabled }"
        action="https://resource.darsing.net/api/v1/img"
        name="img"
        :file-list="coverFileList"
        :headers="uploadHeaders"
        :auto-upload="true"
        list-type="picture-card"
        :show-file-list="true"
        :limit="1"
        :on-success="handleCoverImgSuccess"
        :on-exceed="handleCoverImgExceed"
        :on-error="handleImgFail"
        :on-change="changeCoverPictureUpload"
        :on-remove="handleRemoveCoverFile"
      >
        <div slot="default" class="el-icon-plus i-box">
          <span>添加农场封面</span>
        </div>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              class="el-upload-list__item-delete"
              @click="removeCoverFile(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </div>
    <div class="farm-form-items">
      <span class="label">农场位置</span>
      <div class="location">
        <p class="">地址</p>
        <p class="other">
          {{ farmAddress }}
        </p>
        <p class="">位置</p>
        <div id="configure-map" class="map">
          <div
            class="out-box"
            v-if="!longitudeValue || !latitudeValue || !pathsArr.length"
          >
            <img
              src="../../assets/image/configure/farm/icon3.png"
              class="icon3-img"
            />
          </div>
          <img
            src="../../assets/image/configure/farm/icon2.png"
            class="icon2-img"
            @click="editLocation"
          />
          <div
            class="map-info"
            v-if="configureMap && longitudeValue && latitudeValue && areaValue"
          >
            经度{{ longitudeValue }}，纬度{{ latitudeValue }} 面积：{{
              areaValue
            }}m²
          </div>
        </div>
      </div>
    </div>
    <div class="farm-form-items">
      <span class="label">荣誉证书</span>
      <el-upload
        class="upload-img"
        ref="upload"
        :class="{ disabled: isUploadDisabled }"
        action="https://resource.darsing.net/api/v1/img"
        name="img"
        :file-list="fileList"
        :headers="uploadHeaders"
        :auto-upload="true"
        list-type="picture-card"
        :show-file-list="true"
        :limit="9"
        multiple
        :on-success="handleImgSuccess"
        :on-error="handleImgFail"
        :on-exceed="handleImgExceed"
        :on-change="changePictureUpload"
        :on-remove="removeFile"
      >
        <div slot="default" class="el-icon-plus i-box">
          <img src="../../assets/image/configure/farm/icon1.png" />
          <span>添加证书</span>
        </div>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!uploadDisabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </div>
    <div class="farm-form-items">
      <span class="label">简介标题</span>
      <el-input
        v-model.trim="territory"
        placeholder="请输入简介标题"
        maxlength="20"
      ></el-input>
    </div>
    <div class="farm-form-items margin-bottom50px">
      <span class="label">企业介绍</span>
      <MyQuillEditor :desc="desc" ref="myQuillEditor"></MyQuillEditor>
    </div>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="1076px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-pop-main">
        <div class="top">
          <!-- <el-input v-model="address" placeholder="搜索地点"></el-input>
          @click="searchByKeyword"
          <div class="search-btn">搜索</div> -->
          <div
            class="operations"
            v-for="(item, index) in operationList"
            :key="index"
            @click="changeOperations(index)"
            :class="operationIndex === index ? 'active' : ''"
          >
            <img
              :src="operationIndex === index ? item.activeSrc : item.defaultSrc"
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div
          id="pop-map"
          class="pop-map"
          v-loading="loading"
          v-show="addShow"
        ></div>
        <div class="bottom">
          <div class="infos">
            <div class="tag">
              <span>当前标记地点</span>
              <el-input
                class="item"
                v-model.trim="farmAddress"
                autocomplete="off"
                placeholder="请输入地址"
                maxlength="20"
              ></el-input>
            </div>
            <div class="other">
              <span>经度</span>
              <el-tooltip
                class="item"
                effect="light"
                :content="'' + longitudeValue"
                :disabled="!longitudeValue"
                placement="top-start"
              >
                <div class="other-val one-txt-cut">{{ longitudeValue }}</div>
              </el-tooltip>
              <span>纬度</span>
              <el-tooltip
                class="item"
                effect="light"
                :content="'' + latitudeValue"
                :disabled="!latitudeValue"
                placement="top-start"
              >
                <div class="other-val one-txt-cut">{{ latitudeValue }}</div>
              </el-tooltip>
              <span>面积</span>
              <el-tooltip
                class="item"
                effect="light"
                :content="'' + areaValue"
                :disabled="!areaValue"
                placement="top-start"
              >
                <div class="other-val">{{ areaValue }}</div>
              </el-tooltip>
            </div>
          </div>
          <div class="dialog-footer">
            <el-button class="ok-button" type="primary" @click="okAdd"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noAdd">取 消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { getFarmInfoApi, postEditFarmApi } from "@/request/api";
import { mapState, mapMutations } from "vuex";

import * as turf from "@turf/turf";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.min.css";
import { Map, View } from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import LayerVector from "ol/layer/Vector";
import SourceVector from "ol/source/Vector";
import Polygon from "ol/geom/Polygon";
import { defaults, Draw, Modify } from "ol/interaction";
import { Style, Fill, Stroke, Icon } from "ol/style";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
export default {
  components: {
    MyQuillEditor: () => import("@/components/MyQuillEditor.vue"),
  },
  data() {
    return {
      type: "None",
      draw: null,
      modify: null,
      polygonLayer: null,
      vectorLayer: null,
      vectorSource: null,
      pointLayer: null,
      source: new SourceVector({
        wrapX: false,
      }),
      pageSource: new SourceVector({
        wrapX: false,
      }),
      // 区域加载动画
      loading: false,
      // 延时器
      setTimeOut: null,
      setTimeOut2: null,
      // 地图
      popMaps: null,
      configureMap: null,
      // 经纬度
      longitudeValue: "",
      latitudeValue: "",
      // 面积
      areaValue: "",
      // 多边形
      pathsArr: [],
      mark_data: [],
      // 弹窗
      addShow: false,
      operationIndex: null,
      name: "",
      farmAddress: "",
      desc: "",
      territory: "",
      operationList: [
        {
          name: "定位标记",
          activeSrc: require("../../assets/image/configure/farm/icon4.png"),
          defaultSrc: require("../../assets/image/configure/farm/icon5.png"),
        },
        {
          name: "边界绘制",
          activeSrc: require("../../assets/image/configure/farm/icon6.png"),
          defaultSrc: require("../../assets/image/configure/farm/icon7.png"),
        },
      ],
      // 上传荣誉证书
      imageUrl: "",
      screen_page: "",
      vr_page: "",
      menu_power: "",
      imgArrList: [],
      fileList: [],
      // 预览
      dialogImageUrl: "",
      dialogVisible: false,
      uploadDisabled: false,
      isUploadDisabled: false, //控制上传按钮
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      // 上传农场封面
      coverImageUrl: "",
      coverImgArrList: [],
      coverFileList: [],
      isCoverUploadDisabled: false,
    };
  },
  watch: {
    addShow(n) {
      if (!n) {
        this.operationIndex = null;
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.getFarmInfoApi();
  },
  beforeDestroy() {
    if (this.setTimeOut) {
      clearTimeout(this.setTimeOut);
      this.setTimeOut = null;
    }
    if (this.setTimeOut2) {
      clearTimeout(this.setTimeOut2);
      this.setTimeOut2 = null;
    }
  },
  methods: {
    ...mapMutations("user", ["SET_FARM_INFO"]),

    startModify() {
      if (this.type !== "Polygon") return;
      this.modify = new Modify({
        source: this.source,
      });
      this.popMaps.addInteraction(this.modify);
      this.modify.on("modifyend", (e) => {
        let i2 = e.features.array_[0].getGeometry().getCoordinates()[0];
        this.showArea(i2);
        this.pathsArr = i2;
      });
    },
    endModify() {
      if (this.modify !== null) {
        this.popMaps.removeInteraction(this.modify);
      }
    },
    endDraw() {
      if (this.draw !== null) {
        this.popMaps.removeInteraction(this.draw);
      }
    },
    startDraw() {
      if (this.draw !== null) {
        this.popMaps.removeInteraction(this.draw);
      }
      if (this.type === "Polygon") {
        this.draw = new Draw({
          source: this.source,
          type: this.type,

          style: new Style({
            fill: new Fill({
              color: "rgba(255,255,255,.3)",
            }),
            stroke: new Stroke({
              width: 4,
              color: "#409EFF",
            }),
          }),
        });
        this.popMaps.addInteraction(this.draw);
        this.draw.on("drawend", (e) => {
          let i2 = e.feature.getGeometry().getCoordinates()[0];
          this.showArea(i2);
          this.pathsArr = i2;
          this.popMaps.removeInteraction(this.draw);
          this.startModify();
        });
      }
    },
    showArea(i2) {
      let polygon = turf.polygon([i2]);
      this.areaValue = Math.round(turf.area(polygon));
    },
    showPolygon() {
      let f = new Feature({
        geometry: new Polygon([this.pathsArr]),
      });
      f.setStyle(
        new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.3)",
          }),
          stroke: new Stroke({
            width: 4,
            color: "#3e90e5",
          }),
        })
      );
      this.source.addFeature(f);
      this.polygonLayer = new LayerVector({
        source: this.source,
      });
    },
    // 预览图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 修改农场信息
    async postEditFarmApi() {
      let position = this.longitudeValue + "," + this.latitudeValue;
      let mark_data = JSON.stringify(this.pathsArr);
      let certs = JSON.stringify(this.imgArrList);
      let photo = this.coverImageUrl;
      const { code } = await postEditFarmApi({
        manor_id: this.farmID,
        title: this.name,
        address: this.farmAddress,
        border_color: "#3777FF",
        body_color: "#3777FF",
        alpha: 30,
        mark_type: 3,
        position,
        area: this.areaValue,
        certs,
        intro: this.$refs.myQuillEditor.content,
        weight: 0,
        custom_state: 1,
        mark_data,
        photo,
        territory: this.territory,
      });
      if (code === 0) {
        let obj = {};
        obj.address = this.farmAddress;
        obj.area = this.areaValue;
        obj.title = this.name;
        obj.photo = this.coverImageUrl;
        obj.position = position;

        obj.screen_page = this.screen_page;
        obj.menu_power = this.menu_power;
        obj.name = this.name;
        obj.zoom = this.zoom;

        obj.vr_page = this.vr_page;
        this.SET_FARM_INFO({ farmInfo: obj });
        localStorage.setItem("farmInfo", JSON.stringify(obj));

        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
      }
    },
    // 获取农场基本信息
    async getFarmInfoApi() {
      const { code, results } = await getFarmInfoApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.screen_page = results.screen_page;
        this.vr_page = results.vr_page;
        this.menu_power = results.menu_power;
        this.name = results.title;
        this.farmAddress = results.address;
        this.desc = results.intro;
        this.territory = results.territory;
        this.areaValue = results.area;
        this.imgArrList = JSON.parse(results.certs) || [];
        // 封面的回显
        if (results.photo) {
          this.isCoverUploadDisabled = true;
          this.coverImageUrl = results.photo;
          this.coverFileList = [
            {
              url: this.uploadImgs + results.photo,
            },
          ];
        }
        // 图片列表回显
        this.imgArrList.forEach((element) => {
          this.fileList.push({
            url: this.uploadImgs + element,
          });
        });
        this.longitudeValue = +results.position.split(",")[0];
        this.latitudeValue = +results.position.split(",")[1];
        this.pathsArr = JSON.parse(results.mark_data) || [];
        this.showPolygon();
        this.showPoint();
        this.mark_data = results.mark_data;
        if (results.mark_data) {
          this.setTimeout2 = setTimeout(() => {
            this.initMap();
          }, 80);
        }
      }
    },
    // 上传失败
    handleImgFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    // 超出上传数量
    handleImgExceed() {
      this.$notify({
        title: "提示",
        message: "最多上传9张图片",
      });
    },
    // 超出上传数量
    handleCoverImgExceed() {
      this.$notify({
        title: "提示",
        message: "最多上传1张封面图片",
      });
    },
    // 删除上传的图片
    handleRemove(file) {
      this.removeFile(file);
    },
    // 删除封面上传的图片
    handleRemoveCoverFile(file) {
      this.removeCoverFile(file);
    },
    // 删除图片
    removeFile(file) {
      let fileLists = this.$refs.upload.uploadFiles;
      this.isUploadDisabled = !fileLists.length >= 9;
      let index = fileLists.findIndex((fileItem) => fileItem.uid === file.uid);
      fileLists.splice(index, 1);
      this.imgArrList.splice(index, 1);
    },
    // 删除封面图片
    removeCoverFile(file) {
      let coverFileList = this.$refs.coverUpload.uploadFiles;
      this.isCoverUploadDisabled = !coverFileList.length >= 1;
      let index = coverFileList.findIndex(
        (fileItem) => fileItem.uid === file.uid
      );
      coverFileList.splice(index, 1);
      this.coverImgArrList.splice(index, 1);
      this.coverImageUrl = "";
    },
    // on-change钩子
    changePictureUpload(file, fileList) {
      this.isUploadDisabled = fileList.length >= 9;
    },
    // 封面on-change钩子
    changeCoverPictureUpload(file, fileList) {
      this.isCoverUploadDisabled = fileList.length >= 1;
    },
    // 上传成功
    handleImgSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "图片上传成功",
          type: "success",
        });
        this.imgArrList.push(this.imageUrl);
      }
    },
    // 封面上传成功
    handleCoverImgSuccess(res, file) {
      if (file.percentage === 100) {
        this.coverImageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "图片上传成功",
          type: "success",
        });
        this.coverImgArrList.push(this.coverImageUrl);
      }
    },
    // 取消编辑农场地图
    noAdd() {
      this.addShow = false;
      this.type = "None";
    },
    // 确定农场地图的修改
    okAdd() {
      if (this.mark_data === null) {
        this.showPoint();
        this.initMap();
      } else {
        this.showPoint();
      }
      this.addShow = false;
      this.type = "None";
      this.endModify();
    },
    addPoint() {
      // 创建图层
      this.vectorLayer = new LayerVector();
      // 创建数据容器
      this.vectorSource = new SourceVector();
      // 把数据容器添加到图层
      this.vectorLayer.setSource(this.vectorSource);
      if (this.pathsArr.length) {
        this.pointLayer = new Feature({
          name: "point点",
          geometry: new Point([this.longitudeValue, this.latitudeValue]),
        });
        this.pointLayer.setStyle(
          new Style({
            image: new Icon({
              src: require("@/assets/image/configure/farm/icon4.png"),
            }),
          })
        );
        this.vectorSource.addFeature(this.pointLayer);
        // 添加到地图上
        this.popMaps.addLayer(this.vectorLayer);
      }
    },
    //添加定位图标
    setPoint(local) {
      this.pointLayer = new Feature({
        name: "point点",
        geometry: new Point(local),
      });
      this.pointLayer.setStyle(
        new Style({
          image: new Icon({
            src: require("@/assets/image/configure/farm/icon4.png"),
          }),
        })
      );
      this.vectorSource.addFeature(this.pointLayer);
      // if (!this.pathsArr.length) {
      //   this.popMaps.addLayer(this.vectorLayer);
      // }
      this.popMaps.addLayer(this.vectorLayer);

      //定位动画
      this.popMaps.getView().animate({
        center: local,
        zoom: 13,
        rotation: undefined,
        duration: 500,
      });
    },
    // 显示点
    showPoint() {
      this.pageSource.clear();
      let pointFeature = new Feature({
        name: "point",
        geometry: new Point([this.longitudeValue, this.latitudeValue]),
      });
      pointFeature.setId(+new Date());
      this.pageSource.addFeature(pointFeature);
    },
    // 弹窗地图初始化
    initPopMap() {
      if (this.pathsArr.length) {
        let f = new Feature({
          geometry: new Polygon([this.pathsArr]),
        });
        this.source.addFeature(f);
      }
      let vector = new LayerVector({
        source: this.source,
        style: new Style({
          fill: new Fill({
            color: "rgba(255,255,255,.3)",
          }),
          stroke: new Stroke({
            width: 4,
            color: "#409EFF",
          }),
        }),
      });
      this.popMaps = new Map({
        // 创建地图
        target: "pop-map",
        layers: [
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          vector,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [this.longitudeValue, this.latitudeValue],
          zoom: 13,
          maxZoom: 18,
          constrainResolution: true,
          smoothResolutionConstraint: false,
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new defaults({
          doubleClickZoom: false,
        }),
      });
      this.addPoint();
      this.popMaps.on("singleclick", (evt) => {
        if (this.type === "Point") {
          const coordinate = evt.coordinate;
          this.longitudeValue = coordinate[0];
          this.latitudeValue = coordinate[1];
          this.vectorSource.clear();
          this.setPoint(coordinate);
        }
      });
    },
    // 页面小地图初始化
    initMap() {
      // tryHideFullScreenLoading();
      let pointLayer = new LayerVector({
        source: this.pageSource,
        style: new Style({
          image: new Icon({
            src: require("@/assets/image/configure/farm/icon4.png"),
          }),
        }),
      });
      this.configureMap = new Map({
        // 创建地图
        target: "configure-map",
        layers: [
          // 底图层
          new TileLayer({
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 注记层
          new TileLayer({
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer,
          pointLayer,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [this.longitudeValue, this.latitudeValue],

          zoom: 13,
          maxZoom: 18,
          constrainResolution: true,
          smoothResolutionConstraint: false,
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new defaults({
          doubleClickZoom: false,
        }),
      });
      this.mark_data = 1;
    },

    // 坐标/边界 功能选择
    changeOperations(index) {
      this.operationIndex = index;
      if (index) {
        this.getDraw();
      } else {
        this.getLocation();
      }
    },
    // 定位标记
    getLocation() {
      this.type = "Point";
      if (this.modify === null) {
        this.endDraw();
      } else {
        this.endModify();
      }
    },
    // 边界绘制
    getDraw() {
      this.type = "Polygon";
      if (this.draw === null && !this.pathsArr.length) {
        this.startDraw();
      } else {
        this.startModify();
      }
    },
    // 编辑农场地图
    editLocation() {
      this.addShow = true;
      if (!this.popMaps) {
        this.setTimeOut = setTimeout(() => {
          this.initPopMap();
        }, 50);
      }
    },
    // 关闭窗口
    setAddClose() {
      this.addShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.farm {
  flex: 1;
  flex-direction: column;
  display: flex;
  background: url("../../assets/image/configure/farm/bgimg.png") no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #3e90e5;
  }
  .template-page-top {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    margin-bottom: 22px;
    // position: sticky;
    // position: -webkit-sticky;
    // top: 60px;
    .title {
      width: 50%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
      color: #fff;
    }
    /deep/.preserve {
      width: 100%;
      text-align: right;
      .button-preserve {
        display: inline-block;
      }
    }
  }
  /deep/.farm-form-items {
    padding: 0 48px;
    width: 100%;
    display: flex;
    margin-bottom: 38px;
    .label {
      width: 57px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      margin-right: 22px;
      color: #fff;
    }
    .el-input {
      width: 378px;
      .el-input__inner {
        width: 100%;
        background-color: #081c30;
        border: 1px solid #385576;
        &:focus {
          border-color: #3e90e5;
        }
      }
    }
    .location {
      flex: 1;
      display: flex;
      flex-direction: column;
      p {
        font-size: 14px;
        margin-bottom: 14px;
        color: #3e90e5;
        &.other {
          color: #fff;
          margin-bottom: 30px;
        }
      }
      .map {
        width: 511px;
        height: 239px;
        background: #081c30;
        border: 1px solid #385576;
        border-radius: 3px;
        position: relative;
        .out-box {
          width: 100%;
          background: #081c30;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1999;
          .icon3-img {
            width: 38px;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1999;
            transform: translate(-50%, -50%);
          }
        }
        .icon2-img {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 53px;
          cursor: pointer;
          z-index: 1999;
        }
        .map-info {
          width: 100%;
          position: absolute;
          left: 5px;
          bottom: 5px;
          color: #3385ff;
          font-size: 14px;
          z-index: 1999;
        }
      }
    }
    .upload-img {
      flex: 1;
      .i-box {
        width: 157px;
        height: 132px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3e90e5;
        font-size: 14px;
        flex-direction: column;
        img {
          width: 36px;
          height: 36px;
          margin-bottom: 5px;
        }
        position: absolute;
        top: 0;
        left: 0;
        &::before {
          display: none;
        }
      }
      .el-upload--picture-card {
        width: 157px;
        height: 132px;
        width: 148px;
        height: 148px;
        background: #081c30;
        border: 1px dashed #57708d;
        border-radius: 3px;
        position: relative;
        .el-icon-plus.i-box {
          width: 100%;
          height: 100%;
        }
      }

      .el-upload--picture-card i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.disabled {
        .el-upload--picture-card {
          display: none;
        }
      }
    }
    .quill-editor {
      width: 1007px !important;
      height: 340px !important;
      margin-top: 0px !important;
      .ql-toolbar {
        background-color: #f5f5f5;
      }
      .ql-container {
        height: 79%;
        background-color: #f5f5f5;
      }
    }
  }
  .margin-bottom50px {
    margin-bottom: 50px;
  }
  .big-pop {
    /deep/.el-dialog {
      width: 1076px !important;
      background-color: inherit;
      margin-top: 0 !important;
      .el-dialog__header {
        padding-top: 5px;
      }
    }

    /deep/.el-dialog__body {
      padding: 0;
    }
    // 弹窗右上角
    /deep/.el-dialog__headerbtn {
      font-size: 40px;
      font-weight: bold;
      top: 68px;
      right: 72px;
    }
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.big-pop-main {
      width: 1076px;
      height: 860px;
      background: url(../../assets/image/configure/farm/popbgimg.png) no-repeat
        center center;
      background-size: cover;
      padding: 96px 55px 0 55px;
      .top {
        display: flex;
        width: 100%;
        margin-bottom: 31px;
        .search-btn {
          width: 90px;
          height: 40px;
          background: #3e90e5;
          border-radius: 3px;
          font-size: 14px;
          color: #fff;
          margin: 0 88px 0 20px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
        .el-input {
          width: 378px;
          .el-input__inner {
            width: 100%;
            background-color: #081c30;
            border: 1px solid #385576;
            &:focus {
              border-color: #3e90e5;
            }
          }
        }
        .operations {
          width: 117px;
          height: 40px;
          border: 1px solid #385576;
          border-radius: 3px;
          margin-right: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #fff;
          cursor: pointer;
          img {
            margin-right: 6px;
            width: 22px;
          }
          &.active {
            color: #3e90e5;
            border-color: #3e90e5;
          }
        }
      }
      .pop-map {
        height: 514px;
        width: 100%;
        margin-bottom: 31px;
      }
      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        .infos {
          flex: 1;
          display: flex;
          flex-direction: column;
          .tag {
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            span {
              font-size: 14px;
              margin-right: 13px;
              color: #3385ff;
            }
            .el-input__inner {
              background-color: #081c30;
              border: 1px solid #385576;
              color: #fff;
              width: 350px;
              height: 40px;
            }
            .el-textarea__inner {
              background-color: #081c30;
              border: 1px solid #385576;
              color: #fff;
              width: 350px;
              height: 40px;
              resize: none;
              &:focus {
                border-color: #3e90e5;
              }
            }
          }
        }
        .other {
          display: flex;
          align-items: center;
          span {
            margin-right: 13px;
            font-size: 14px;
            color: #3385ff;
          }
          .other-val {
            margin-right: 25px;
            width: 86px;
            height: 40px;
            line-height: 40px;
            background: #081c30;
            border: 1px solid #385576;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;
            padding: 0 22px;
          }
        }
      }
    }
  }
  /deep/.dialog-footer {
    margin: 24px 0 34px;
    padding: 0 0 0 125px;
    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
      color: #fff;
      &:hover {
        color: #3385ff;
      }
    }
  }
}
</style>
